<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 sm8 md8 lg12 pa-6>
          <v-tabs color="#766BC0" grow class="nsbold" style="font-size: 16px">
             <v-tab>Outpass Report</v-tab>
            <v-tab>Leave Report</v-tab>
            <v-tab>Attendance Report</v-tab>
            <v-tab>Visitor Report</v-tab>
            <v-tab>Incidents Report</v-tab>
            <v-tab-item>
              <adminOutpassRpt/>
            </v-tab-item>
            <v-tab-item>
              <adminLeaveRpt/>
            </v-tab-item>
            <v-tab-item>
              <adminAtndcRpt/>
            </v-tab-item>
             <v-tab-item>
              <adVisitorRpt/>
            </v-tab-item>
            <v-tab-item> 
              <adIncidentRpt/>
            </v-tab-item>
          </v-tabs>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
import adminOutpassRpt from "./adminOutpassRpt"
import adminLeaveRpt from "./adminLeaveRpt"
import adminAtndcRpt from "./adminAtndcRpt"
import adVisitorRpt from "./adVisitorRpt"
import adIncidentRpt from "./adIncidentRpt"

export default {
  components : {
    adminOutpassRpt,
    adminLeaveRpt,
    adminAtndcRpt,
    adVisitorRpt,
    adIncidentRpt,
  },
  data() {
    return {
      page: 1,
      Pagelength: 0,
      keyword: null,
      ServerError: false,
      showsnackbar: false,
      msg: null,
      appLoading: false,
    };
  },
  
};
</script>