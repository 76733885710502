ting<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card
          class="elevation-2 pa-6"
          align="center"
          outlined
        >
          <!----------------------filters---------------------------->
          <v-layout wrap>
            <v-flex xs12 sm6 lg6 text-left pt-2>
              <span class="nsbold" style="font-size:20px">
                Attendance Report
              </span>
            </v-flex>
            <v-flex xs12 sm3 lg3 py-2 py-sm-0>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="From"
                    outlined
                    readonly
                    dense
                    hide-details
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  no-title
                  scrollable
                  @change="$refs.menu.save(fromDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm3 lg3 pl-sm-4>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="toDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toDate"
                    label="To"
                    readonly
                    outlined
                    dense
                    hide-details
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  no-title
                  scrollable
                  @change="$refs.menu2.save(toDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
           
            <!--------------------------list------------------------>
          </v-layout>
          <!---------------------------------------------------->
          <v-layout wrap justify-center>
            <v-flex xs12 v-if="attendance" px-0 pt-2>
              <v-layout wrap>
                <v-flex xs12 v-if="attendance.length > 0">
                  <v-expansion-panels>
                    <v-expansion-panel v-for="(item, i) in attendance" :key="i">
                      <v-expansion-panel-header
                        v-if="item._id"
                        :style="i % 2 == 0 ? 'background-color:#eeeeee' : ''"
                      >{{formatDate2(item.date)}}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                         <v-simple-table>
                        <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">Date</th>
                          <th class="text-left">Percentage</th>
                          <th class="text-left">Present</th>
                          <th class="text-left">Absent</th>
                          <th class="text-left">Leave</th>
                          <th class="text-left">Outpass</th>
                        </tr>
                      </thead>
                       <tbody>
                        <tr v-for="(item2, i) in item.data" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                          <td>
                            {{ formatDate2(item2.date) }}({{item2.sessionId.name}})
                          </td>
                          <td>{{ item2.attendanePercentage.toFixed(2) }}</td>
                          <td>
                            <router-link
                                :to="'/adAttenList?id=' + item2._id +'&name=' +item2.sessionId.name+'&date=' +item2.createddate"
                                style="text-decoration: none"
                              >
                            <v-btn elevation="0" small title="View List">
                              
                            <span v-if="item2.numberOfStudentsPresent >= 0">
                              {{ item2.numberOfStudentsPresent }}
                            </span>
                            <span v-else>NA </span>
                            </v-btn>
                            </router-link>
                          </td>
                          <td>
                            <router-link
                                :to="'/adAbscList?id=' + item2._id +'&name=' +item2.sessionId.name+'&date=' +item2.createddate"
                                style="text-decoration: none"
                              >
                            <v-btn elevation="0" small title="View List">
                            <span v-if="item2.numberOfStudentsAbsent >= 0">
                              {{ item2.numberOfStudentsAbsent }}
                            </span>
                            <span v-else>NA</span>
                            </v-btn>
                            </router-link>
                          </td>
                          <td>
                            <router-link
                                :to="'/adLeaveList?id=' + item2._id +'&name=' +item2.sessionId.name"
                                style="text-decoration: none"
                              >
                            <v-btn elevation="0" small title="View List">
                            <span v-if="item2.numberOfStudentsLeave >= 0">
                              {{ item2.numberOfStudentsLeave }}
                            </span>
                            <span v-else>NA</span>
                             </v-btn>
                            </router-link>
                          </td>
                         <td>
                          <router-link
                                :to="'/adOutpList?id=' + item2._id +'&name=' +item2.sessionId.name"
                                style="text-decoration: none"
                              >
                            <v-btn elevation="0" small title="View List">
                            <span v-if="item2.numberOfStudentsOutPass >= 0">
                              {{ item2.numberOfStudentsOutPass }}
                            </span>
                            <span v-else>NA</span>
                             </v-btn>
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                        </template>
                         </v-simple-table>
                      </v-expansion-panel-content>
                       </v-expansion-panel>
                  </v-expansion-panels>
                  <!-- <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">Date</th>
                          <th class="text-left">Percentage</th>
                          <th class="text-left">Present</th>
                          <th class="text-left">Absent</th>
                          <th class="text-left">Leave</th>
                          <th class="text-left">Outpass</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in attendance" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                          <td>
                            {{ formatDate2(item.date) }}({{item.sessionId.name}})
                          </td>
                          <td>{{ item.attendanePercentage.toFixed(2) }}</td>
                          <td>
                            <router-link
                                :to="'/attendanceList?id=' + item._id +'&name=' +item.sessionId.name"
                                style="text-decoration: none"
                              >
                            <v-btn elevation="0" small title="View List">
                            <span v-if="item.numberOfStudentsPresent">
                              {{ item.numberOfStudentsPresent }}
                            </span>
                            <span v-else>NA</span>
                            </v-btn>
                            </router-link>
                          </td>
                          <td>
                            <router-link
                                :to="'/absentList?id=' + item._id +'&name=' +item.sessionId.name"
                                style="text-decoration: none"
                              >
                            <v-btn elevation="0" small title="View List">
                            <span v-if="item.numberOfStudentsAbsent >= 0">
                              {{ item.numberOfStudentsAbsent }}
                            </span>
                            <span v-else>NA</span>
                            </v-btn>
                            </router-link>
                          </td>
                          <td>
                            <span v-if="item.numberOfStudentsLeave >= 0">
                              {{ item.numberOfStudentsLeave }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                         <td>
                            <span v-if="item.numberOfStudentsOutPass >= 0">
                              {{ item.numberOfStudentsOutPass }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table> -->
                </v-flex>
                <v-flex v-else xs12 align-self-center text-center pa-2>
                  <span class="nsregular">Currently no item found!</span>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 pt-4>
                   <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
                </v-flex>
              </v-layout>
             
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
      Pagelength: 0,
      menu: false,
      menu2: false,
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      keyword: null,
      msg: null,
      //    issueStatus : ["All","Approved", "Out", "Active", "In"],
      cvalue: "All",
      showsnackbar: false,
      ServerError: false,
      appLoading: false,
      attendance: null,
    };
  },
  mounted() {
    if (this.fromDate) {
      var dt = new Date();

      // var day=dt.getDate()
      dt.setDate(dt.getDate() - 30 + 1);
      this.fromDate = dt.toISOString().substr(0, 10);
    }
    if (this.from) {
      var dlt = new Date();

      // var day=dlt.getDate()
      dlt.setDate(dlt.getDate() - 30 + 1);
      this.fromDate = dlt.toISOString().substr(0, 10);
    }
    this.getData();
  },
  watch: {
    page() {
      // this.getData();
      this.getData();
    },
    fromDate() {
      if (this.page > 1) this.page = 1;
      this.getData();
    },
    toDate() {
      if (this.page > 1) this.page = 1;

      this.getData();
    },
    keyword() {
      if (this.page > 1) this.page = 1;
      // this.getData();
      this.getData();
    },
    cvalue() {
      if (this.page > 1) this.page = 1;
      // this.getData();
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;

      //   let a;
      //   if (this.cvalue == "All") {
      //     a = "";
      //   } else {
      //     a = this.cvalue;
      //   }
      axios({
        method: "post",
        url: "attendance/session/group",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          page: this.page,
          limit: 20,
          //   issueStatus : a,
          keyword: this.keyword,
          fromDate: this.fromDate,
          toDate: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.attendance = response.data.attendancedata;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
    formatDate2(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
